export const enum Currencies {
  usd = "USD",
}

export const MIN_BUY_AMOUNT = 1;
export const MAX_BUY_AMOUNT = 100000;

export const DEFAULT_USD_LIMITS = [
  { minBuyAmountUSD: MIN_BUY_AMOUNT, maxBuyAmountUSD: MAX_BUY_AMOUNT },
];

export const USD_CURRENCY: CurrencyWithLimits = {
  id: 3,
  name: Currencies.usd,
  fullName: "US Dollar",
  symbol: "$",
  icon: "https://api-staging.coindisco.com/media/currencies/icons/USD.png",
  isPopular: true,
  rate: 1,
  default: true,
  amountLimits: {
    min: MIN_BUY_AMOUNT,
    max: MAX_BUY_AMOUNT,
  },
};
