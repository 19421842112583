interface AsyncDebounce<T extends Array<unknown>, R> {
  (...args: T): Promise<Awaited<R>>;
  cancel: VoidFunction;
}

export const debounceAsync = <
  T extends Array<unknown>,
  R extends Promise<unknown>,
>(
  callback: (...args: T) => R,
  wait = 300,
): AsyncDebounce<T, R> => {
  const store = { id: null };

  const cancel = () => {
    if (store.id) {
      clearTimeout(store.id);
      store.id = null;
    }
  };

  const result = (...args: T) => {
    cancel();

    return new Promise<Awaited<R>>((resolve, reject) => {
      store.id = setTimeout(
        () =>
          callback(...args)
            .then(resolve)
            .catch(reject),
        wait,
      );
    });
  };

  result.cancel = cancel;

  return result;
};

export function promiseEater<R extends CallableFunction>(callback: R) {
  const store = { promise: null };

  return (...args) => {
    if (store.promise?.abort) {
      store.promise.abort();
    }

    return (store.promise = callback(...args));
  };
}

export const sleep = (time: number) =>
  new Promise(resolve => setTimeout(resolve, time));

export const callWithAsyncContext = <R, C extends object>(
  fn: (...args: unknown[]) => R,
  context: C,
): R => {
  if (!("_asyncStore" in globalThis)) {
    throw new Error(
      "The callWithContext method cannot be used on the client side.",
    );
  }

  return globalThis._asyncStore.run(context, fn);
};

export const getAsyncContext = <C extends object>(defaultCx?: C): C => {
  if ("_asyncStore" in globalThis) {
    return globalThis._asyncStore.getStore();
  }

  return defaultCx;
};
