export enum ChartRanges {
  "1D" = "1D",
  "1W" = "1W",
  "1M" = "1M",
  "1Y" = "1Y",
  "All" = "All",
}

export const CHART_RANGES_LIST = Object.values(ChartRanges);

export const DEFAULT_CHART_RANGE = ChartRanges["1D"];

export const MAX_FAVORITES_COUNT = 100;

export const BITCOIN_LETTER_ID = "bitcoin";
export const BITCOIN_NETWORK_ID = 8;
